

.div_hover:hover {
    background-color:#f4f4f4 !important;
    cursor: pointer;
    
  }

  .notification-detail-heading{
    font-weight: 900;
    margin-top:4rem; 
    margin-bottom: 1rem;
    font-size:2rem;
    padding:20px 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .deatils-section{
    margin-top:2rem;
    padding-left:30px;
    padding-bottom:30px;
    padding-top:10px;
    padding-right:50px;
    /* background:lightgray */
}

.notifications-heading{
    font-weight: 900;
    margin-top:3rem; 
    margin-bottom: 1rem;
    font-size:2rem;
    padding:50px 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.notification-content-div{
    width:100%;
    text-align:left;
    border:none;
    /* box-shadow:10px 10px #f8f9fa; */
    padding:20px 10px;
    margin-bottom: 20px;
    /* box-shadow: 10px 10px 5px 0px rgba(255,255,255,0.75);
-webkit-box-shadow: 10px 10px 5px 0px rgba(255,255,255,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(255,255,255,0.75); */
box-shadow: rgba(62, 57, 107, 0.07) 0px 0px 20px 0px, rgba(62, 57, 107, 0.06) 0px 2px 9px 0px !important;
border-radius: 10px;
}
.conditional-background{
  background-color: #d9f2f2c4 !important;
}

.flex-time-status{
    display:flex;
    justify-content:space-between
}

/* .editor-styles{
  min-height: 344px !important;
} */

.jodit-wysiwyg{
  min-height: 245px !important;
}

.user-table-cells{
  font-weight:700;
  font-size:16px;
  width:800px;
  text-align:left
}

.users-heading{
  font-weight: 900;
  margin-top:3rem; 
  margin-bottom: 1rem;
  font-size:2rem;
  padding:0px 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  /* Add any other styling to indicate it's disabled */
}

.support-notices-heading{
  font-weight: 900; 
  margin-bottom: 2rem;
  font-size:2rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}