.admin-div{
    display:flex;
    justify-content:flex-end;
    /* padding:20px 30px */
}

.realtive-icon{
    position: relative !important;
}

.icon-style{
    text-align:center;
    position:absolute;
    border-radius:50%;
    border:solid 1px red;
    width:25px;
    height:25px;
    right:-10 !important;
    top:4 !important;
    background-color:red
}

.notification-number{
    text-align:center;
    margin:0;
    color:white;
    font-size:14px
}